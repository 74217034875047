























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import html2pdf from 'html2pdf.js';
import { copyObject,
  downloadFile,
  duplicateDataItems,
  duplicateDataItemsWithoutSelect,
  unselectFirstElement } from '@/helper/functions';
import priceStatusIcon from '@/components/form/price-status-icon.vue';
import apiBackend from '@/service/api-insurance';
// import {getTabsHelper} from '@/helper/functions';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';

const versicherungsortItem = {
  isSelected: false,
  num: 0,
  Strabe: '',
  PLZ: '',
  Ort: '',
  Land: '',
  ZursZone: '',
  Naturgefahren: '',
  Naturgefahren2: '',
  Naturgefahren3: '',
  geltungsbereich: '',
  individualAgreements: '',
};
const versicherungsortType = 1;

const DeckungsanpassungenJeRisikoItem = {
  open: true,
  FeuerAusschlussGemabTK1232: 'nein',
  LeitungswasserAusschlussGemabTK1233: 'nein',
  EinbruchdiebstahlAusschlussGemabTK1234: 'nein',
  DiebstahlAusschlussGemabTK1235: 'nein',
  AusschlussUberschwemmung: 'nein',
  AusschlussSturm: 'nein',
  GrenzeDerEntschadigungGemabTK1722: 'nein',
  GrenzeDerEntschadigung: null,
  Nachlass: null,
  Erdbeben: '',
  ErdbebenSelbstbeteiligung: null,
  JHE: null,
  InnereUnruhenMitversicherungGemabTK1236: 'nein',
  ErweiteterGeltungsbereichGemabTK1408: '',
  Geltungsbereich: '',
  RegressverzichtGemabTK1820: '',
  BU: '',
  MehrkostenGemabTK1930: '',
  ZeitabhangigeMehrkosten: '',
  Freitext: '',
  ZeitlicheSelbstbeteiligung: '',
  Haftzeit: '',
  ZeitunabhangigeMehrkosten: '',
  Selbstbeteiligung: null,
  SelbstbeteiligungPercentage: null,
  SelbstbeteiligungMin: null,
  SelbstbeteiligungMax: null,
  Haftzeit2: '',
  BeitragFurDieMitversicherungTK1930: null,
  SelbstbeteiligungPercentageTk1408: 25,
  ZuschlagPercentageTk1408: 0,
};
const deckungsanpassungenJeRisikoType = 2;

const erstrisikosummenAdditionalItem = {
  name: '',
  ERS: null,
  GeanderteERS: null,
};
const erstrisikosummenType = 3;

const regionsMapping = {
  Deutschland: 10,
  Europa: 20,
  Weltweit: 30,
};

const positionsDaten = {
  num: 0,
  isSelected: false,
  Gerateart: '',
  Beschreibung: '',
  MKZ: '',
  Versicherunswert: '',
  Baujahr: null,
  Hersteller: null,
  Seriennummer: null,
  Typ: null,
  Bewertungsjahr: '',
  Versicherungssumme: '',
  TK1507AngleichungVNr: '',
  Versicherungsort: null,
  Selbstbeteiligung: '',
  Versicherungssummenstaffel: '',
  Beitragssatz: '',
  AuslagerungInEinenAnderenGefahrenFeuerbereich: '',
  ProzentualerSB: '',
  NachlassAufgrundErganzungstarif: '',
  Sondervereinbarungen: '',
  DeckungsanpassungenJeRisikoItems: [{
    ...DeckungsanpassungenJeRisikoItem,
  }],
};

export default Vue.extend({
  name: 'elektronik-insurance-ELEKErganzungstarif',
  mixins: [dataChangeMixin],
  components: {
    priceStatusIcon,
  },
  data() {
    return {
      name: 'elektronik-insurance-ELEKErganzungstarif',
      key: 'ELEKTRONIK_INSURANCE',
      versicherungsortItems: [
        copyObject(versicherungsortItem),
      ],
      byVersicherungsortType: versicherungsortType,
      byPositionsType: 4,
      byDeckungsanpassungenJeRisikoType: deckungsanpassungenJeRisikoType,
      positionsDatenItems: [
        copyObject(positionsDaten),
      ],
      SelbstbeteiligungHardware: {
        tarif: {
          name: 'Tarif-SB',
          SB: null,
          SBRabattfaktor: null,
          TarifpramiensatzAktuellNetto: null,
          Tarifpramiensatz371Netto: null,
          TarifpramieNetto: null,
        },
        variant1: {
          name: 'SB-Variante 1',
          SB: null,
          SBRabattfaktor: null,
          TarifpramiensatzAktuellNetto: null,
          Tarifpramiensatz371Netto: null,
          TarifpramieNetto: null,
        },
        variant2: {
          name: 'SB-Variante 2',
          SB: null,
          SBRabattfaktor: null,
          TarifpramiensatzAktuellNetto: null,
          Tarifpramiensatz371Netto: null,
          TarifpramieNetto: null,
        },
        variant3: {
          name: 'SB-Variante 3',
          SB: null,
          SBRabattfaktor: null,
          TarifpramiensatzAktuellNetto: null,
          Tarifpramiensatz371Netto: null,
          TarifpramieNetto: null,
        },
      },
      erstrisikosummen: {
        aufraumungsBergungsDekontaminationsUndEnsorgungskosten: {
          name: 'Aufräumungs-, Bergungs-, Dekontaminations- und Entsorgungskosten',
          ERS: null,
          geanderteERS: null,
        },
        dekontaminationsUndEntsorgungskostenFurErdreich: {
          name: 'Dekontaminations- und Entsorgungskosten für Erdreich',
          ERS: null,
          geanderteERS: null,
        },
        bewegungsUndSchutzkosten: {
          name: 'Bewegungs- und Schutzkosten',
          ERS: null,
          geanderteERS: null,
        },
        luftfrachtkosten: {
          name: 'Luftfrachtkosten',
          ERS: null,
          geanderteERS: null,
        },
        bergungskosten: {
          name: 'Bergungskosten',
          ERS: null,
          geanderteERS: null,
        },
        kostenFurErdPflasterMauerUndStemmarbeiten: {
          name: 'Kosten für Erd-, Pflaster-, Mauer- und Stemmarbeiten',
          ERS: null,
          geanderteERS: null,
        },
      },
      erstrisikosummenAdditionalItems: [] as any,
      byErstrisikosummenType: erstrisikosummenType,
      notizen: '',
      pdfStyles: false,
      refImportPlacesFileName: 'excelImportPlaces',
      loadedData: false,
      refImportFileName: 'excelImport',
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'id', 'productId', 'processSavingData', 'priceData'],
  watch: {
    processSavingData(value: boolean) {
      if (this.insuranceData && this.insuranceData.basicData && this.firstOpenTab) {
        this.fillVersicherungsortItemsFromGruppendaten(this.insuranceData.basicData.versicherungsort);
        this.loadedData = true;
      }
    },
  },
  methods: {
    uploadPlacesFile(element) {
      const file = element.target.files[0];

      if (file) {
        apiBackend.uploadFile(`${this.id}/import-places-excel`, 'import', file).then((response: any) => {
          this.importPlacesFromExcel(response.data);
          this.excelImportPlacesRef.value = null;
        }).catch((e) => {
          console.error(`Error - ${e}`);
        });
      }
    },
    uploadFile(element) {
      const file = element.target.files[0];

      if (file) {
        apiBackend.uploadFile(`${this.id}/import-excel`, 'import', file).then((response: any) => {
          this.importPositionsdatenFromExcel(response.data);
          this.excelImportRef.value = null;
        }).catch((e) => {
          console.error(`Error - ${e}`);
        });
      }
    },
    importPositionsdatenFromExcel(dataItems: any[]) {
      const firstElementEmpty = this.positionsDatenItems.length === 1 &&
        !this.positionsDatenItems[0].Beschreibung &&
        !this.positionsDatenItems[0].MKZ &&
        !this.positionsDatenItems[0].Versicherunswert;

      dataItems.forEach((item, i) => {
        const fillFirst = firstElementEmpty && i === 0;

        const newItem = fillFirst ? this.positionsDatenItems[0] : copyObject(positionsDaten);

        newItem.Beschreibung = item.description;
        newItem.MKZ = `${item.mkz}`;
        newItem.Versicherunswert = item.sumInsured;
        newItem.Bewertungsjahr = item.assessmentYear;
        newItem.Baujahr = item.constructionYear;
        newItem.Hersteller = item.manufacturer;
        newItem.Seriennummer = item.serialNumber;
        newItem.Typ = item.type;

        if (!fillFirst) {
          this.positionsDatenItems.push(newItem);
        }
      });
      console.log('importPositionsdatenFromExcel this.positionsDatenItems', this.positionsDatenItems);
    },
    chooseImportFile() {
      this.excelImportRef.click();
    },
    importPlacesFromExcel(dataItems: any[]) {
      const firstElementEmpty = this.versicherungsortItems.length === 1 &&
        !this.versicherungsortItems[0].Strabe &&
        !this.versicherungsortItems[0].PLZ &&
        !this.versicherungsortItems[0].Ort &&
        !this.versicherungsortItems[0].Land;

      dataItems.forEach((item, i) => {
        const fillFirst = firstElementEmpty && i === 0;

        const newItem = fillFirst ? this.versicherungsortItems[0] : copyObject(versicherungsortItem);

        newItem.Strabe = item.street;
        newItem.PLZ = item.postCode;
        newItem.Ort = item.place;
        newItem.Land = item.land;

        if (!fillFirst) {
          this.versicherungsortItems.push(newItem);
        }
      });
    },
    exportPlacesData() {
      const data = this.versicherungsortItems.map((itm) => ({
        street: itm.Strabe,
        code: itm.PLZ,
        city: itm.Ort,
        land: itm.Land,
      }));

      apiBackend.exportPlacesData(data).then((response) => {
        downloadFile(response.data, 'Versicherungsort-ELEKErganzungstarif.xlsx');
      });
    },
    chooseImportPlacesFile() {
      this.excelImportPlacesRef.click();
    },
    goToBackUrl() {
      if (this.prevTabItem) {
        this.$emit('go-back-tab', this.prevTabItem.path, 'insuranceForms', this.prevTabItem.key);
      } else {
        this.$emit('go-back-tab', 'elektronik-insurance', 'insuranceForms', 1);
      }
    },
    fillVersicherungsortItemsFromGruppendaten(versicherungsort: any) {
      this.versicherungsortItems[0].Strabe = versicherungsort.Strabe;
      this.versicherungsortItems[0].PLZ = versicherungsort.PLZ;
      this.versicherungsortItems[0].Ort = versicherungsort.Ort;
      this.versicherungsortItems[0].ZursZone = versicherungsort.ZursZone;
      this.versicherungsortItems[0].Naturgefahren = versicherungsort.Naturgefahrenklasse;
    },
    exportToPDF() {
      const filename = `Zusammenfassung-ELEK-Erganzungstarif-${this.id}.pdf`;
      this.pdfStyles = true;
      html2pdf(document.getElementById('element-to-convert'), {
        margin: 10,
        filename,
        html2canvas: {
          width: 1500,
        },
      }).then((response) => {
        this.pdfStyles = false;
      });
    },
    deleteErstrisikosummenItem(key) {
      this.erstrisikosummenAdditionalItems.splice(key, 1);
    },
    changedScope(pos: number, index: number) {
      const newValue = this.positionsDatenItems[pos].DeckungsanpassungenJeRisikoItems[index].Geltungsbereich;

      this.positionsDatenItems[pos].Positionsdaten.DeckungsanpassungenJeRisikoItems[index].ZuschlagPercentageTk1408 = regionsMapping[newValue] || 0;
    },
    dublicateRow(fillData: boolean, type: number) {
      switch (type) {
        case this.byVersicherungsortType:
          duplicateDataItems(versicherungsortItem, this.versicherungsortItems, fillData);
          break;
        case this.byErstrisikosummenType:
          duplicateDataItemsWithoutSelect(erstrisikosummenAdditionalItem, this.erstrisikosummenAdditionalItems);
          break;
        case this.byPositionsType:
          duplicateDataItems(positionsDaten, this.positionsDatenItems, fillData);
          break;
        default:
          console.log('Wrong type');
      }
    },
    showAuslagerungInEinenAnderenGefahrenFeuerbereichAndProzentualerSB(pos: number): boolean {
      return this.positionsDatenItems[pos].MKZ === '0917';
    },
    showNachlassAufgrundErganzungstarif(pos: number): boolean {
      return this.positionsDatenItems[pos].MKZ && this.positionsDatenItems[pos].MKZ.startsWith('09');
    },

    duplicateDeckung(pos: number) {
      duplicateDataItemsWithoutSelect(DeckungsanpassungenJeRisikoItem, this.positionsDatenItems[pos].DeckungsanpassungenJeRisikoItems);
    },
    deleteRows(type: number) {
      let items;

      switch (type) {
        case this.byVersicherungsortType:
          unselectFirstElement(this.versicherungsortItems);

          items = this.versicherungsortItems.filter((item) => !item.isSelected);
          this.versicherungsortItems = items;
          break;
        case this.byPositionsType:
          unselectFirstElement(this.positionsDatenItems);

          items = this.positionsDatenItems.filter((item) => !item.isSelected);
          this.positionsDatenItems = items;
          break;
        default:
          console.log('Wrong type');
      }
    },
    setValues(insuranceData: any) {
      const calculationData = insuranceData;
      this.versicherungsortItems = calculationData.versicherungsortItems;
      this.positionsDatenItems = calculationData.positionsDatenItems;
      this.SelbstbeteiligungHardware = calculationData.SelbstbeteiligungHardware;
      this.erstrisikosummen = calculationData.erstrisikosummen;
      this.erstrisikosummenAdditionalItems = calculationData.erstrisikosummenAdditionalItems;
      this.notizen = calculationData.notizen;
    },
    onSubmit(goNextPage = true) {
      const data = {
        versicherungsortItems: this.versicherungsortItems,
        positionsDatenItems: this.positionsDatenItems,
        SelbstbeteiligungHardware: this.SelbstbeteiligungHardware,
        erstrisikosummen: this.erstrisikosummen,
        erstrisikosummenAdditionalItems: this.erstrisikosummenAdditionalItems,
        notizen: this.notizen,
      };
      if (goNextPage) {
        this.$emit('insurance-forms-data-change', data, 'ELEKErganzungstarif', this.nextTabId);
      } else {
        this.$emit('insurance-forms-data-change', data, 'ELEKErganzungstarif', 3);
      }
    },
    downloadInsurancePlacesTemplate() {
      apiBackend.downloadInsurancePlaces().then((response) => {
        downloadFile(response.data, 'Einlesen von Versicherungsorten.xlsx');
      });
    },
    downloadPositionsDataTemplate() {
      apiBackend.downloadPositionsData().then((response) => {
        downloadFile(response.data, 'Einlesen von Gerätelisten.xlsx');
      });
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    if (this.insuranceData && this.insuranceData.insuranceFormsData && this.insuranceData.insuranceFormsData.ELEKErganzungstarif) {
      this.setValues(this.insuranceData.insuranceFormsData.ELEKErganzungstarif);
    }
    (this as any).startWatcherActivated();
  },
  computed: {
    excelImportPlacesRef(): any {
      return this.$refs[this.refImportPlacesFileName];
    },
    prevTabItem(): any {
      return this.$store.getters['tabsModule/getPrevTab']('insuranceForms', 3);
    },
    versicherungsortSelectOptions(): any[] {
      return this.versicherungsortItems.map((item) => item.num);
    },
    firstOpenTab(): boolean {
      // eslint-disable-next-line no-prototype-builtins
      return (this.insuranceData === null || this.insuranceData.insuranceFormsData === null || !this.insuranceData.insuranceFormsData.hasOwnProperty('ELEKErganzungstarif')) && (!this.loadedData);
    },
    showPlusBtn(): boolean {
      return (this.erstrisikosummenAdditionalItems.length < 5);
    },
    showMinusBtn(): boolean {
      return (this.erstrisikosummenAdditionalItems.length > 1);
    },
    nextTabId() {
      const activeTabs = this.insuranceData.basicData.Produktauswahl;
      const result = 0;
      if (activeTabs.ELEKPauschal) {
        return 4;
      }
      if (activeTabs.Praxispolice) {
        return 5;
      }
      return result;
    },
    excelImportRef(): any {
      return this.$refs[this.refImportFileName];
    },
  },
});
